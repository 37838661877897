// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes, useParams } from 'react-router-dom';

import loadable from '@loadable/component';
import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'sonner';

import Error404 from './pages/Error404';
import StakeholderAuthRoute from './pages/StakeholderAuth';
import isDevelopment from './utils/helpers/isDevelopment';
import useCheckIdentity from './utils/hooks/useCheckIdentity';
import useGetIdentity from './utils/hooks/useGetIdentity';
import useGetPermaLinkIdentity from './utils/hooks/useGetPermaLinkIdentity';
import { useIdentity } from './utils/hooks/useIdentity';
import { usePermaLinkIdentity } from './utils/hooks/usePermaLinkIdentity';
import { defaultQueryClient } from './utils/setup';

const Customer = loadable(() => import('./pages/Customer'));
const StakeholderRoute = loadable(() => import('./pages/Stakeholder'));

const ReactQueryDevtools = loadable(() => import('@tanstack/react-query-devtools'), {
  resolveComponent: (components) => components.ReactQueryDevtools,
});

function CsrdApp() {
  return (
    <QueryClientProvider client={defaultQueryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="realm/:companyId/*">
            <Route path="*" element={<IsAuthenticated />}>
              <Route path="customer/*" element={<Customer />} />
            </Route>
          </Route>

          <Route path="perma-link/:token/stakeholder/*" element={<IsPermaLinkAuthenticated />}>
            <Route path="*" element={<StakeholderRoute />} />
          </Route>

          <Route path="/404" element={<Error404 />} />
          <Route path="/perma-link/:token/*" element={<StakeholderAuthRoute />} />
          <Route path="*" element={<IsAuthenticated />} />
        </Routes>
      </BrowserRouter>

      <Toaster duration={3500} expand={true} />
      {isDevelopment() && <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default CsrdApp;

function IsAuthenticated() {
  const { isError, error, isLoading } = useGetIdentity();
  const { logout } = useIdentity();
  useCheckIdentity();

  useEffect(() => {
    if (isError) {
      console.error('Error fetching Identity', error);
      logout();
    }
  }, [error, isError, logout]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
}

function IsPermaLinkAuthenticated() {
  const { isError, error, isLoading } = useGetPermaLinkIdentity();
  const { logout } = usePermaLinkIdentity();
  const { token } = useParams();

  if (!token) {
    logout();
  }

  useEffect(() => {
    if (isError) {
      console.error('Error fetching Identity', error);
      logout();
    }
  }, [error, isError, logout]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
}
