import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { type Identity } from '../../@types/api/Identity';
import isDevelopment from '../helpers/isDevelopment';
import { logoutUrl } from '../setup';

const identityKey = 'gc_identity';

enum Roles {
  COMPANY_USER = 'COMPANY_USER',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  AUDITOR = 'AUDITOR',
  FREEMIUM_USER = 'FREEMIUM_USER',
}

type IdentityStore = {
  identity: Identity | undefined;
  setIdentity: (identity: Identity) => void;
  clearIdentity: () => void;
  logout: () => void;
};

export const useIdentity = create<IdentityStore>()(
  devtools(
    persist(
      (set, get) => ({
        identity: get()?.identity ?? undefined,
        setIdentity: (value: Identity) => {
          const isCompanyUser = !!value.selectedCompany?.roles.includes(Roles.COMPANY_USER);
          const isCompanyAdmin = !!value.selectedCompany?.roles.includes(Roles.COMPANY_ADMIN);
          const isFreemiumUser = !!value.selectedCompany?.roles.includes(Roles.FREEMIUM_USER);
          const isAuditor = !!value.selectedCompany?.roles.every((role) => ['AUDITOR', 'COMPANY_USER'].includes(role)); // If a User has only AUDITOR and COMPANY_USER roles
          const activeLicenses = value?.selectedCompany?.licenseInfo.filter((license: { type: string; validUntil: number }) => license.validUntil > Date.now());
          const hasCSRDLicense = !!activeLicenses?.some((license: { type: string; validUntil: number }) => license.type.includes('CSRD'));

          set({ identity: { ...value, isCompanyUser, isCompanyAdmin, isFreemiumUser, isAuditor, hasCSRDLicense: hasCSRDLicense || isDevelopment() } });
        },
        clearIdentity: clearIdentity,
        logout: () => {
          clearIdentity();
          window.location.replace(`${logoutUrl}?redirect_url=${window.location.href}`);
        },
      }),
      {
        name: identityKey,
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

function clearIdentity() {
  if (isDevelopment()) {
    console.log('clearIdentity');
  }
  useIdentity.persist.clearStorage();
}

export function getCompanyIdFromUrl() {
  const url = new URL(window.location.href);

  const pathArray = url.pathname.split('/').filter(Boolean);
  const indexOfUrl = pathArray.indexOf('realm') + 1;
  return pathArray[indexOfUrl] ?? undefined;
}
