import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import BASE_CONFIG from '../../BASE_CONFIG';

function useDocumentTitle(title?: undefined | string | string[]) {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const pageTitleArray = [BASE_CONFIG.pageTitleBase, t('appTitle'), title];
    window.document.title = pageTitleArray
    .flat(Number.POSITIVE_INFINITY)
    .filter(Boolean)
    .join(BASE_CONFIG.pageTitleSeparator);
  }, [t, title]);
}

export default useDocumentTitle;
