import { type ReactNode } from 'react';

import background from '../../assets/png/perma-link-auth-bg.png';

function StakeholderAuthLayout({ children }: { children?: ReactNode }) {
  return (
    <main
      style={{
        backgroundImage: `url(${background})`,
      }}
      className="min-h-screen min-w-[1152px] bg-cover bg-center"
    >
      <div className="size-full min-h-[inherit] bg-gradient-to-t from-blue-500">{children}</div>
    </main>
  );
}

export default StakeholderAuthLayout;
