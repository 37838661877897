import axios, { type AxiosError } from 'axios';

import { ENV } from '../setup';

/**
 *
 * @param baseURL
 * @param language
 */
export default function defaultAxiosSettings(baseURL: string, language: string | undefined) {
  const companyId = extractCompanyId();
  const permaLinkToken = extractPermaLinkToken();

  axios.defaults.baseURL = baseURL;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['X-Language'] = language;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['X-Company'] = companyId || '';
  axios.defaults.headers.common['X-PERMA-LINK'] = permaLinkToken || '';

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: AxiosError) {
      // TEMP-GUARD

      console.log(error?.response?.status);
      if (error?.response?.status === 401 && window.location.pathname !== '/') {
        // window.location.assign('/');
        window.location.assign(`${ENV.appPublicURL}/auth/login`);
      }
      return Promise.reject(error);
    },
  );
}

/** @TODO: WE DONT RELY ON FE FOR COMPANY SELECTION */
function isUUID(str: string): boolean {
  const regexExp = /^[\da-f]{8}(?:\b-[\da-f]{4}){3}\b-[\da-f]{12}$/gi;
  return regexExp.test(str);
}

export function extractCompanyId(): string | undefined {
  const parts = window.location.pathname?.split('/');
  if (parts[1] === 'realm' && parts.length > 2) {
    const companyId = parts[2];
    if (companyId && isUUID(companyId)) return companyId;
  }
}

export function extractPermaLinkToken(): string | undefined {
  const parts = window.location.pathname?.split('/');
  if (parts.length > 2 && parts[1] === 'perma-link') {
    return parts[2];
  }
}

// type Test = {
//   state: {
//     stakeholderIdentity: StakeholderIdentity;
//   };
//   version: number;
// };

// export function extractStakeholderId(): string | undefined {
//   // take it from session storage with key 'gc_perma_link_identity'
//   const stakeholderIdentity = sessionStorage.getItem('gc_perma_link_identity');
//   if (stakeholderIdentity) {
//     const parsedIdentity: Test = JSON.parse(stakeholderIdentity);
//     if (parsedIdentity.state && parsedIdentity.state.stakeholderIdentity) {
//       return parsedIdentity.state.stakeholderIdentity.stakeholderId;
//     }
//   }
// }
