import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import CsrdApp from './CsrdApp';
import './styles/index.scss';
import isDevelopment from './utils/helpers/isDevelopment';
import setup from './utils/setup';

if (isDevelopment()) {
  console.warn('Running in Development mode!');
}

setup();

const root = document.querySelector('#csrd-root');
if (root) {
  createRoot(root).render(
    <StrictMode>
      <CsrdApp />
    </StrictMode>,
  );
} else {
  console.error('Could not find root element');
}
