import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import useDocumentTitle from '../../utils/hooks/useDocumentTitle';
import './stakeholderAuth.scss';

function StakeholderAuthContentWrapper() {
  const { t } = useTranslation();
  useDocumentTitle([t('auth.title')]);

  return (
    <div className="flex min-h-[inherit] flex-col items-center justify-center">
      <Outlet />
    </div>
  );
}

export default StakeholderAuthContentWrapper;
