import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { type PermaLinkIdentity } from '../../@types/api/PermaLinkIdentity';
import isDevelopment from '../helpers/isDevelopment';
import { ENV, logoutUrl } from '../setup';

const permaLinkIdentityKey = 'gc_perma_link_identity';

type PermaLinkIdentityStore = {
  permaLinkIdentity: PermaLinkIdentity | undefined;
  setPermaLinkIdentity: (identity: PermaLinkIdentity) => void;
  clearPermaLinkIdentity: () => void;
  logout: () => void;
};

type SessionStoragePermaLinkIdentity = {
  state: {
    permaLinkIdentity: PermaLinkIdentity;
  };
  version: number;
};

export const usePermaLinkIdentity = create<PermaLinkIdentityStore>()(
  devtools(
    persist(
      (set, get) => ({
        permaLinkIdentity: get()?.permaLinkIdentity ?? undefined,
        setPermaLinkIdentity: (value: PermaLinkIdentity) => {
          set({ permaLinkIdentity: { ...(value || isDevelopment()) } });
        },
        clearPermaLinkIdentity: clearPermaLinkIdentity,
        logout: () => {
          const permaLinkIdentityData: SessionStoragePermaLinkIdentity | null = JSON.parse(sessionStorage.getItem('gc_perma_link_identity') ?? 'null');

          if (permaLinkIdentityData && 'state' in permaLinkIdentityData) {
            const url = new URL(window.location.href);
            const pathSegments = url.pathname.split('/');
            const permaLinkIdIndex = pathSegments.indexOf('perma-link');
            clearPermaLinkIdentity();
            if (permaLinkIdIndex !== -1 && pathSegments.length > permaLinkIdIndex + 1) {
              const id = pathSegments[permaLinkIdIndex + 1];
              // NOTE: this language can't be dynamic because on this level, the i18n is not yet init. so for now we use the de as default
              // this is for testing if you curious about the i18n, feel free to uncomment this line below
              // console.log(i18n.language);
              window.location.href = id ? `${ENV.appPublicURL}/de/perma-link/${id}/login` : logoutUrl;
            }
          }
        },
      }),
      {
        name: permaLinkIdentityKey,
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

function clearPermaLinkIdentity() {
  if (isDevelopment()) {
    console.log('clearPermaLinkIdentity');
  }
  usePermaLinkIdentity.persist.clearStorage();
}
