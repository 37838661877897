enum DocxReportType {
  ESRS_2 = 'ESRS_2',
  ESRS_E1 = 'ESRS_E1',
  ESRS_E2 = 'ESRS_E2',
  ESRS_E3 = 'ESRS_E3',
  ESRS_E4 = 'ESRS_E4',
  ESRS_E5 = 'ESRS_E5',
  ESRS_G1 = 'ESRS_G1',
  ESRS_S1 = 'ESRS_S1',
  ESRS_S2 = 'ESRS_S2',
  ESRS_S3 = 'ESRS_S3',
  ESRS_S4 = 'ESRS_S4',
}

export default DocxReportType;
