import { Navigate, Route, Routes } from 'react-router-dom';

import StakeholderAuthLayout from '../../components/Layout/StakeholderAuthLayout';
import { stakeholderAuthNavigationRoutes } from '../../utils/routeEntry';
import StakeholderAuthContentWrapper from './StakeholderAuthContentWrapper';

function LoginRoute() {
  return (
    <StakeholderAuthLayout>
      <Routes>
        <Route path="*" element={<StakeholderAuthContentWrapper />}>
          {Object.values(stakeholderAuthNavigationRoutes).map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        <Route index path="*" element={<Navigate replace to={stakeholderAuthNavigationRoutes.check.path} />} />
      </Routes>
    </StakeholderAuthLayout>
  );
}

export default LoginRoute;
