import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCompanyIdFromUrl, useIdentity } from './useIdentity';

function useCheckIdentity() {
  const { identity, logout } = useIdentity();

  const navigate = useNavigate();

  // const cid = identity?.selectedCompany?.companyId ?? companyId ?? getCompanyIdFromUrl();
  const cid = getCompanyIdFromUrl();

  if (!cid) {
    logout();
  }

  useEffect(() => {
    if (!identity) return;
    if (!cid) return console.warn('no identity');
    const urlPathBase = `/realm/${cid}`;

    const pathnameArray = new Set(window.location.pathname.split('/').filter(Boolean));

    if (!identity.hasCSRDLicense) {
      navigate(`${urlPathBase}/customer/information`, { replace: true });
    }

    if (cid && !window.location.pathname.includes(urlPathBase)) {
      navigate(`${urlPathBase}`, { replace: true });
      return;
    }

    // if (window.location.pathname === `${urlPathBase}` || window.location.pathname === `${urlPathBase}/`) {
    if ([...pathnameArray].pop() === cid && (identity.isCompanyUser || identity.isCompanyAdmin || identity.isAuditor)) {
      navigate(`${urlPathBase}/customer/information`, { replace: true });
      return;
    }

    if (window.location.pathname.includes(`${urlPathBase}/stakeholder`) && pathnameArray.has(cid)) {
      if (identity.isCompanyUser || identity.isCompanyAdmin || identity.isAuditor) {
        navigate(`/realm/${cid}/customer/information`, { replace: true });
        return;
      }
    } else if (
      (window.location.pathname.includes(`${urlPathBase}/customer`) && pathnameArray.has(cid) && identity.isCompanyUser) ||
      identity.isCompanyAdmin ||
      identity.isAuditor
    ) {
      return;
    }
    console.warn('no role detected => logout');
    logout();
  }, [cid, identity, identity?.selectedCompany?.roles, logout, navigate]);
}

export default useCheckIdentity;
