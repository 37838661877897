import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';

import fetchPermaLinkIdentity from '../services/api/permaLinkIdentity';
import { usePermaLinkIdentity } from './usePermaLinkIdentity';

function useGetPermaLinkIdentity() {
  const { permaLinkIdentity, setPermaLinkIdentity, clearPermaLinkIdentity } = usePermaLinkIdentity();
  const { token } = useParams();
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const { data, isLoading, isError, isSuccess, error, isFetched, isFetching } = useQuery({
    queryKey: ['perma-link-identity'],
    queryFn: fetchPermaLinkIdentity,
    select: (data) => data.data,
    retry: false,
    throwOnError: false,

    staleTime: hoursToMilliseconds(1),
    gcTime: hoursToMilliseconds(6),
    refetchInterval: minutesToMilliseconds(15),
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !isInitialLoaded || (!permaLinkIdentity && !!token),
  });

  // on unmount clear identity
  useEffect(() => {
    return () => {
      clearPermaLinkIdentity();
      setIsInitialLoaded(false);
    };
  }, [token, clearPermaLinkIdentity]);

  useEffect(() => {
    if (isSuccess && data && token) {
      setIsInitialLoaded(true);
      setPermaLinkIdentity(data);
    }
  }, [data, isSuccess, setPermaLinkIdentity, token]);

  return { identityData: data ?? permaLinkIdentity, isLoading, isFetched, isFetching, isError, isSuccess, error };
}

export default useGetPermaLinkIdentity;
