import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';

import type { Identity } from '../../@types/api/Identity';
import fetchIdentity from '../services/api/identity';
import { useIdentity } from './useIdentity';

function useGetIdentity() {
  const { identity, setIdentity, clearIdentity } = useIdentity();
  const { companyId } = useParams();
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const { data, isLoading, isError, isSuccess, error, isFetched, isFetching } = useQuery({
    queryKey: ['identity'],
    queryFn: fetchIdentity,
    select: (data) => data.data,
    retry: false,
    throwOnError: false,
    staleTime: hoursToMilliseconds(1),
    gcTime: hoursToMilliseconds(6),
    refetchInterval: minutesToMilliseconds(15),
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !isInitialLoaded || ((!identity || (identity && identity.loginExpiresAt - Date.now() < 900)) && !!companyId),
  });

  const getCompany = useCallback(
    function getCompanyId(data: Identity) {
      if (data.companies?.length === 1) {
        return data.companies[0];
      } else if (companyId && data.companies?.some((company) => company.companyId === companyId)) {
        return data.companies.find((company) => company.companyId === companyId);
      }

      return;
    },
    [companyId],
  );

  // on unmount clear identity
  useEffect(() => {
    return () => {
      clearIdentity();
      setIsInitialLoaded(false);
    };
  }, [companyId, clearIdentity]);

  useEffect(() => {
    if (isSuccess && data && companyId) {
      setIsInitialLoaded(true);

      const currentCompany = getCompany(data);

      if (currentCompany?.companyId) {
        setIdentity({ ...data, selectedCompany: currentCompany });
        return;
      }
      setIdentity({ ...data, selectedCompany: currentCompany });
    }
  }, [companyId, data, getCompany, isFetched, isSuccess, setIdentity]);

  return { identityData: data ?? identity, isLoading, isFetched, isFetching, isError, isSuccess, error };
}

export default useGetIdentity;
