function Error404() {
  return (
    <div>
      <h1>Role Error</h1>
      <p>Sorry, you don't have access to this page.</p>
    </div>
  );
}

export default Error404;
