import { QueryClient } from '@tanstack/react-query';
import i18next from 'i18next';

import defaultAxiosSettings from './services/axios';

export const ENV = {
  baseURL: import.meta.env.VITE_BASE_ENDPOINT as string,
  appPublicURL: import.meta.env.VITE_APP_PUBLIC_URL as string,
  appAdminURL: import.meta.env.VITE_APP_ADMIN_URL as string,
  appAppURL: import.meta.env.VITE_APP_APP_URL as string,
  appNextCsrdURL: import.meta.env.VITE_APP_NEXT_CSRD_URL as string,
} as const;

export default function setup() {
  defaultAxiosSettings(ENV.baseURL, i18next.resolvedLanguage);
}

export const defaultQueryClient = new QueryClient();

export const logoutUrl = `${ENV.appPublicURL}/auth/logout` as const;
